@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import 'famfamfam-flags/dist/sprite/famfamfam-flags.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root.light {
    color-scheme: light;
  }

  :root.dark {
    color-scheme: dark;
    @apply bg-black-900 text-white;
  }

  .dark input {
    @apply bg-black-900 text-white;
  }

  .dark :-webkit-autofill {
    @apply bg-black-900 text-white;
  }

  .dark :-webkit-input-placeholder {
    @apply bg-black-900 text-white;
  }

  .dark :-webkit-autofill,
  .dark :-webkit-autofill:hover,
  .dark :-webkit-autofill:focus,
  .dark :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #111827 inset;
    -webkit-text-fill-color: white;
  }

  html {
    font-family: Inter, system-ui, sans-serif;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0px 0px 0px 1000px #ffffff inset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  @keyframes animatePadding {
    from {
      padding-top: 20px;
    }

    to {
      padding-top: 10px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader {
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    @apply border-4 rounded-full;
  }

  .animate-padding {
    animation: animatePadding 0.2s forwards;
  }

  .react-tooltip {
    @apply !bg-gray4 !text-sm !p-2;
  }

  .text-4xl {
    @apply text-[40px] leading-[48px] font-semibold !important;
  }

  .text-3xl {
    @apply text-[32px] leading-[40px] font-semibold !important;
  }

  .text-2xl {
    @apply text-[28px] leading-[32px] font-semibold !important;
  }

  .text-xl {
    @apply text-[24px] leading-[28px] font-semibold !important;
  }

  .text-lg {
    @apply text-[20px] leading-[24px] font-semibold !important;
  }

  .text-md {
    @apply text-[16px] leading-[22px] font-semibold !important;
  }

  .text-sm {
    @apply text-[14px] leading-[20px] font-semibold !important;
  }

  .text-xs {
    @apply text-[12px] leading-[16px] font-semibold !important;
  }

  .text-bodyLg-semibold {
    @apply text-[16px] leading-[22px] font-medium !important;
  }

  .text-bodyMd-semibold {
    @apply text-[14px] leading-[20px] font-medium !important;
  }

  .text-bodySm-semibold {
    @apply text-[12px] leading-[16px] font-medium !important;
  }

  .text-bodyLg {
    @apply text-[16px] leading-[22px] font-normal !important;
  }

  .text-bodyMd {
    @apply text-[14px] leading-[20px] font-normal !important;
  }

  .text-bodySm {
    @apply text-[12px] leading-[16px] font-normal !important;
  }

  .text-bodyLg-underline {
    @apply text-[16px] leading-[22px] font-normal underline !important;
  }

  .text-bodyMd-underline {
    @apply text-[14px] leading-[20px] font-normal underline !important;
  }

  .text-bodySm-underline {
    @apply text-[12px] leading-[16px] font-normal underline !important;
  }
}

.custom-input {
  @apply appearance-none h-10 border border-lightGray2 hover:border-primary-500 py-[7px] px-2.5 leading-tight duration-200;

  &:focus {
    @apply border-primary-500 outline-none;
  }

  &::placeholder {
    @apply text-darkGray3;
  }
}

.custom-textarea {
  @apply appearance-none bg-white resize-y dark:bg-darkBlue4 border rounded border-lightGray2 hover:border-blue py-2.5 px-2 leading-tight text-gray2 duration-200 dark:text-darkGray3 dark:placeholder:text-darkGray3 dark:hover:border-blue;

  &:focus {
    @apply border-blue2 outline-none ring-2 ring-lightBlue dark:ring-blue5 dark:border-none;
  }

  &::placeholder {
    @apply text-darkGray3;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    @apply w-1.5;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-lightGray2 rounded-lg dark:bg-darkBlue4 dark:bg-opacity-50;
  }

  &::-webkit-scrollbar-track {
    @apply bg-white dark:bg-darkBlue3;
  }
}

.custom-checkbox {
  @apply transition duration-200 appearance-none w-5 h-5 rounded border-3 border-neutral-400 dark:border-neutral-600 bg-transparent;
}

.custom-checkbox:hover {
  @apply cursor-pointer border-primary-500;
}

.custom-checkbox:checked {
  @apply bg-primary-500 border-primary-500 bg-no-repeat bg-center bg-[100%];
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' fill='none'%3e%3cpath d='M3 6L5 8.5L9 3.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

.custom-checkbox:disabled {
  @apply cursor-not-allowed border-neutral-300;
}

.custom-checkbox:disabled:hover {
  @apply cursor-not-allowed border-primary-300;
}

.custom-checkbox:disabled:checked  {
  @apply cursor-not-allowed bg-primary-300 border-primary-300;
}

.radio-input-unset-dimensions {
  @apply cursor-pointer transition duration-200 appearance-none border-3 border-neutral-400 dark:border-neutral-600 rounded-full;
}

.radio-input-unset-dimensions:checked {
  @apply border-8 border-primary-500;
}

.radio-input-unset-dimensions:hover {
  @apply border-primary-500;
}

.radio-input-set-v1 > input:checked + label {
  @apply bg-black-900 border-neutral-600;
}

button {
  @apply h-[37px];
}

.hover-language-block,
.hover-theme-block {
  @apply hidden;
}

.language:hover .hover-language-block,
.theme:hover .hover-theme-block {
  @apply block;
}

.dark .language:hover > .menu-item,
.dark .theme:hover > .menu-item {
  @apply bg-darkBlue7;
}

.dark .settings:hover svg,
.dark .language:hover svg,
.dark .theme:hover svg,
.dark .logout:hover svg {
  @apply fill-white;
}

.skeleton-loader {
  @apply bg-skeleton animate-[skeleton_1.5s_ease-in-out_infinite] bg-[length:400%_100%];
}

td,
th {
  @apply p-2;
}

td:last-child,
th:last-child {
  @apply dark:last:border-r-0;
}

.ant-picker-input > input {
  @apply !leading-6 !text-sm !text-darkGray10 placeholder:!text-darkGray3 dark:!text-darkGray4;
}

.ant-picker-cell-inner {
  @apply !rounded-full;
}

.ant-picker-cell-today .ant-picker-cell-inner {
  @apply !bg-gray9 !text-darkGray before:!border-none;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  @apply !bg-lightBlue2 !text-darkGray;
}

.custom-quill-editor {
  .ql-toolbar {
    @apply dark:border-darkBlue3;

    .ql-picker-label {
      @apply dark:text-white;
    }

    .ql-fill {
      @apply dark:fill-white;
    }

    .ql-stroke {
      @apply dark:stroke-white;
    }
  }

  .ql-editor {
    @apply h-64 dark:bg-darkBlue4;

    &::before {
      @apply dark:!text-darkGray4;
    }
  }
  .ql-container {
    @apply dark:border-darkBlue4;
  }
}

.task:last-child {
  @apply mb-7;
}

.date-picker {
  .ant-picker-input > input {
    @apply dark:!text-white;
  }
}

.custom-svg-icon {
  svg > path {
    @apply dark:fill-white;
  }

  svg > polyline {
    @apply dark:stroke-white;
  }
}

#error-page {
  text-align: center;
  font-size: 30px;
  margin-top: 40vh;
}

.p-tree-toggler {
  display: none;
}

.ant-picker-panel-layout {
  @apply dark:!bg-darkBlue4 dark:text-white;

  .ant-picker-date-panel .ant-picker-content th {
    @apply dark:text-white;
  }

  .ant-picker-header {
    span {
      @apply dark:text-gray2 dark:fill-white dark:hover:!text-white;
    }

    button {
      @apply dark:text-white dark:hover:!text-blue5;
    }
  }

  .ant-picker-cell-inner {
    @apply dark:hover:!bg-blue5;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
    .ant-picker-cell-inner {
      @apply dark:!bg-transparent dark:!text-blue5 dark:hover:!bg-blue5 dark:hover:!text-white;
    }
  }

  .ant-picker-cell {
    @apply dark:text-gray2 dark:hover:text-white;
  }

  .ant-picker-cell-in-range {
    @apply dark:!text-gray2;
  }

  .ant-picker-cell-in-view {
    @apply dark:text-white;
  }

  .ant-picker-now-btn {
    @apply dark:hover:!text-blue5;
  }
}
.pac-container {
  @apply border-0;
}

.pac-item:hover {
  @apply bg-gray6;
}

.pac-item {
  border: none;
  font-size: 14px;
  padding: 4px 8px;
}

.dark .pac-container {
  @apply bg-darkBlue4 text-white;
}

.dark .pac-item {
  @apply bg-darkBlue4 text-white;
}

.dark .pac-item:hover {
  @apply bg-darkBlue10 text-white;
}

.dark .pac-item-query {
  @apply text-white;
}

.ant-picker-datetime-panel {
  @apply dark:!bg-darkBlue4 dark:text-white;

  .ant-picker-time-panel .ant-picker-header .ant-picker-header-view {
    @apply dark:text-white dark:hover:!text-blue5;
  }

  .ant-picker-content {
    ul li div {
      @apply dark:!text-white dark:fill-white dark:hover:!bg-blue5;
    }
    .ant-picker-time-panel-column {
      .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected div {
        @apply dark:!bg-blue5;
      }
    }
  }
}

.ant-picker-footer ul li.ant-picker-ok button {
  @apply dark:bg-blue5 dark:border-blue5 bg-blue text-white disabled:opacity-60;
}

.anticon-close-circle svg {
  @apply dark:fill-white;
}

.date-range .ant-picker-suffix {
  @apply bg-blue2 h-9 w-9 m-0 items-center justify-center rounded-r-xs;
}

.date-range .ant-picker-suffix svg {
  @apply fill-white;
}

.p-colorpicker .p-colorpicker-preview {
  @apply w-7 h-7 rounded;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  @apply dark:bg-darkBlue15 bg-lightGray5 mx-1 my-4 flex-row flex flex-wrap w-8 h-2 rounded-xl;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  @apply bg-primary-500;
}

.p-carousel-item {
  @apply mb-1 h-96;
}

.p-icon {
  @apply text-neutral-300 dark:text-neutral-700 w-4 h-4 hover:text-neutral-400 dark:hover:text-neutral-600;
}

.p-carousel .p-carousel-content .p-carousel-next,
.p-carousel .p-carousel-content .p-carousel-prev {
  @apply bg-white-00 dark:bg-black-900 hover:bg-neutral-100 dark:hover:bg-neutral-900 rounded-full w-8 h-8;
}

.p-colorpicker-panel {
  @apply bg-black;
}

.p-colorpicker-color-handle {
  @apply border border-white;
}

.p-colorpicker-hue-handle {
  @apply border-2 border-white;
}

.email-modal-content {
  p {
    @apply font-bold;
  }
  h3 {
    @apply text-xl pb-2;
  }
}
